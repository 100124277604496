.meta {
  @apply z-0 flex items-start justify-start;
  @apply text-sm font-bold uppercase;

  & .lot {
    @apply relative m-0 flex w-3/4 min-w-0 bg-gray-800 px-4 py-1.5;
    @apply whitespace-nowrap font-secondary font-bold uppercase text-white;
    @apply after:absolute after:-right-2 after:top-0 after:z-[2] after:h-full after:w-4 after:-skew-x-[25deg] after:bg-gray-800;

    & + ul li {
      @apply justify-end;
    }

    &.mainAttraction {
      @apply bg-red-base after:bg-red-base;

      & span:nth-of-type(n + 4) {
        @apply hidden;
      }

      & .skew,
      & .status {
        @apply hidden @[310px]/card:inline;
      }
    }

    & .skew {
      @apply -skew-x-20 px-1.5;
    }
  }

  & :global(.badges li) {
    @apply text-right text-sm font-bold uppercase;
  }
}
