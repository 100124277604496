.advancedFilters {
  @apply mt-8 text-center;

  &.visible {
    & .advancedFiltersContainer {
      @apply block;
    }

    & :global(.button) {
      & svg {
        @apply rotate-90;
      }
    }
  }

  & :global(.button) {
    @apply py-2 text-base lg:text-lg;
  }

  & .advancedFiltersContainer {
    @apply mt-4 hidden;
  }
}
