.rangeRefinement {
  @apply flex items-center gap-4 px-4 py-3;

  & input {
    @apply w-full border border-gray-200 px-3 py-1.5 text-base;

    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      @apply appearance-none;
    }
  }
}
