.button {
  @apply inline-flex items-center justify-center;
  @apply rounded-[2px] border-2 border-solid border-current;
  @apply font-primary font-bold uppercase tracking-[1px];
  @apply cursor-pointer hover:no-underline;

  @media (width <= calc(theme('screens.xs') - 1px)) {
    @apply mx-auto w-[280px] max-w-full;
  }

  &.primary,
  &.styleOutline,
  &.red {
    @apply hover:border-red-hover hover:!bg-red-hover hover:!text-white focus:border-red-hover focus:!bg-red-hover focus:!text-white active:border-red-hover active:!bg-red-hover active:!text-white;
  }

  &.green {
    @apply hover:border-green-hover hover:!bg-green-hover focus:border-green-hover focus:!bg-green-hover active:border-green-hover active:!bg-green-hover;
  }

  &.border-color-red {
    @apply border-red-base;
  }

  &.border-color-gray-400 {
    @apply border-gray-400 hover:border-gray-600 hover:!bg-gray-600 focus:border-gray-600 focus:!bg-gray-600 active:border-gray-600 active:!bg-gray-600;
  }

  &.border-color-green {
    @apply border-green-base;
  }

  &.iconLeft {
    @apply flex-row-reverse;

    & svg {
      @apply ml-0 mr-12;
    }
  }

  /* SIZES */
  &.lg {
    @apply px-8 py-4 text-xl;
  }

  &.md {
    @apply px-6 py-3 text-lg;
  }

  &.sm {
    @apply px-2.5 py-1.5;
  }

  &.fluid {
    @apply block w-full text-center;
  }

  &.hasIcon {
    @apply pr-2;

    & svg {
      @apply ml-3;
    }
  }

  /* TYPES */
  &.primary {
    @apply bg-red-base text-white;

    &.disabled {
      @apply opacity-50;
    }
  }

  &.secondary {
    @apply border border-black bg-white hover:bg-gray-100;

    &.disabled {
      @apply opacity-50;
    }
  }

  &.alt,
  &.altOutline {
    @apply rounded-sm border-red-base bg-red-base font-secondary text-base font-bold uppercase tracking-normal text-white transition-all;

    &:hover,
    &:focus {
      @apply border-red-hover bg-red-hover text-white;
    }

    &.outline {
      @apply border-2 border-red-base bg-transparent;
    }

    &.disabled {
      @apply opacity-50;
    }
  }

  &.altOutline {
    @apply border-2 border-red-base bg-transparent;
  }

  &.disabled {
    @apply cursor-not-allowed;
  }

  &.iconOnly {
    @apply p-2;

    & svg {
      @apply m-0;
    }
  }

  /* STYLES */
  &.styleOutline {
    @apply bg-transparent;
  }

  &.styleText {
    @apply border-none !bg-transparent p-0 leading-4 tracking-normal hover:!bg-transparent focus:!bg-transparent;
  }

  /* ALIGNMENTS */
  &.alignCenter {
    @apply mx-auto;
  }

  &.alignRight {
    @apply ml-auto;
  }
}
