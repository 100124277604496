.watchListButton {
  &:hover,
  &:focus {
    & svg {
      @apply fill-gray-700;

      &.isFavourite {
        @apply fill-red-hover;
      }
    }
  }

  & .favouriteIcon {
    @apply fill-[#bbbdbf] md:ml-8;

    &.isFavourite {
      @apply fill-[#ee1c23];
    }
  }
}
