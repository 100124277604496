.badges {
  @apply z-[1] m-0 flex flex-1;

  &.isRelative {
    @apply relative;
  }

  &.isAbsolute {
    @apply absolute;
  }

  &.withMainAttraction {
    & .badge:not(.main-attraction, .no-reserve) {
      @apply hidden;
    }
  }

  & .badge {
    @apply relative flex flex-1 items-center whitespace-nowrap py-1.5 pl-6 pr-3 font-secondary text-xs font-bold uppercase text-white first-of-type:pl-5 md:pl-9 md:pr-4 md:text-base md:first-of-type:pl-7;

    &.withSkewedBorder {
      @apply after:absolute after:-right-2 after:top-0 after:z-[1] after:h-full after:w-4 after:-skew-x-[25deg] after:[background-color:inherit] md:after:-right-3 md:after:w-6;
    }

    &.main-attraction {
      @apply bg-red-base;
    }

    &.no-reserve {
      @apply bg-yellow text-black;
    }

    &.star {
      @apply bg-gray-800;
    }

    &.feature {
      @apply bg-gray-800;
    }

    &.charity {
      @apply bg-purple;
    }
  }
}
