.transport {
  @apply lg:flex xl:pb-3;

  p {
    @apply mb-10 text-base;
  }

  .panelLeft {
    @apply mx-auto max-w-sm lg:mx-0 lg:w-1/2 lg:max-w-none lg:border-r-2 lg:border-r-gray-600 lg:pr-8 xl:w-2/3;

    .ctas {
      @apply text-center xl:flex xl:gap-2 2xl:gap-6;

      .cta {
        @apply xl:w-1/2 xl:rounded-lg xl:border-4 xl:border-gray-600 xl:px-5 xl:py-5 2xl:px-8;

        h3,
        p {
          @apply hidden font-body xl:block;
        }

        h3 {
          @apply -mb-0.5 text-lg text-white;
        }

        p {
          @apply mb-4 text-gray-400;
        }

        .button {
          @apply mb-3 hidden w-full self-start font-secondary tracking-normal xl:flex xl:bg-red-base;

          &.buttonMobile {
            @apply flex xl:hidden;
          }
        }
      }

      .iconArrows {
        @apply hidden 2xl:flex 2xl:flex-col 2xl:justify-center;

        img {
          @apply h-[80px];
        }
      }
    }

    .link {
      @apply lg:hidden;
    }
  }

  .panelRight {
    @apply mx-auto hidden text-center lg:block lg:w-1/2 lg:pl-7 xl:w-1/3;

    .intro {
      @apply mb-0 xl:flex xl:flex-col;
    }

    .button {
      @apply mb-2 w-full font-secondary tracking-normal xl:mx-auto xl:w-auto xl:min-w-[260px];
    }

    .link {
      @apply mx-auto inline-block;
    }
  }
}
