.facetsToggle {
  @apply flex flex-col items-center gap-2 xs:flex-row lg:hidden;

  & .button {
    @apply w-full rounded-sm border-2 border-red-base bg-red-base px-4 py-1.5 leading-5;
    @apply font-primary text-base font-bold uppercase tracking-[1px] text-white;
    @apply hover:border-red-hover hover:bg-red-hover;
    @apply xs:[width:unset];
  }

  & .text {
    @apply text-sm;
  }
}
