.consignment {
  @apply flex flex-col items-center gap-8;

  & select {
    @apply mb-1.5 block w-full max-w-[450px] p-3 text-base font-bold;
    @apply rounded-[3px] border border-gray-500;
    @apply hover:cursor-pointer hover:border-black;
    @apply focus:cursor-pointer focus:border-black;
  }

  & .ctas {
    @apply flex flex-col items-center gap-6;

    & .consign,
    & .download {
      @apply min-w-[250px] self-center font-secondary tracking-normal;
    }

    & .consign {
      @apply disabled:border-gray-400 disabled:bg-gray-400 disabled:hover:!border-gray-400 disabled:hover:!bg-gray-400;
    }

    & .download {
      @apply hover:text-white;
      @apply disabled:border-gray-400 disabled:!text-gray-500 disabled:hover:bg-transparent;
    }
  }
}
