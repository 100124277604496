.heading {
  @apply my-4 leading-none;

  &.primary {
    @apply font-primary;
  }

  &.secondary {
    @apply font-secondary;
  }

  &.body {
    @apply font-body;
  }

  &.alignFull {
    @apply align-full;
  }

  &.alignWide {
    @apply align-wide;
  }

  &.withColoredStripes {
    @apply relative pl-8;
    @apply before:absolute before:left-0 before:top-0 before:h-[90%] before:w-[5px] before:-skew-x-[25deg] before:bg-yellow;
    @apply after:absolute after:left-3 after:top-0 after:h-[90%] after:w-[5px] after:-skew-x-[25deg] after:bg-milano-red;
  }

  &.withColoredStripesRight {
    @apply pl-0 pr-8 before:left-auto before:right-3 after:left-auto after:right-0;
  }

  &.withTyreMark {
    @apply relative py-4 before:pointer-events-none before:absolute before:left-1/2 before:top-1/2 before:hidden before:w-full before:-translate-x-1/2 before:-translate-y-1/2 before:whitespace-nowrap before:leading-normal before:content-[attr(data-text)] before:[font-size:clamp(8rem,16vw,16rem)] before:md:block lg:mt-32 lg:py-12;

    &::before {
      background: linear-gradient(
        180deg,
        rgb(0 0 0 / 10%) 0%,
        rgb(0 0 0 / 0%) 80%
      );
      background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }
  }

  &.withCalloutLineLeft,
  &.withCalloutLineRight {
    @apply relative before:absolute before:top-[0.375em] before:z-[1] before:hidden before:h-[14px] before:w-[172px] before:md:block;

    &::before {
      background: var(--image-url);
      background-size: contain;
    }
  }

  &.withCalloutLineLeft {
    @apply before:-left-[200px];
  }

  &.withCalloutLineRight {
    @apply before:-right-[200px] before:rotate-180;
  }

  & img {
    @apply mx-1 inline;
  }

  &:is(h1, h2, h3) {
    &[data-inview='false'] {
      @apply lg:scale-x-110 lg:opacity-0;
    }
  }

  &:is(h1) {
    &[data-inview='true'] {
      @apply lg:scale-x-100 lg:opacity-100 lg:delay-300 lg:duration-500;
    }
  }

  &:is(h2) {
    &[data-inview='true'] {
      @apply lg:scale-x-100 lg:opacity-100 lg:delay-500 lg:duration-300;
    }
  }

  &:is(h3) {
    &[data-inview='true'] {
      @apply lg:scale-x-100 lg:opacity-100 lg:delay-100 lg:duration-300;
    }
  }
}
