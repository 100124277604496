.accordion {
  & .header {
    @apply border-b border-gray-200;
  }

  & .title {
    @apply mb-0;
    @apply font-secondary text-lg font-bold uppercase tracking-tighter;
  }

  & .accordionButton {
    @apply flex w-full items-center justify-between px-4 py-2;
  }

  &:has(:global(#sale-result-panel .no-refinement)) {
    @apply hidden;
  }
}
