.countdown {
  @apply flex items-center font-secondary text-xl uppercase text-white;

  &.standard {
    @apply flex-col gap-1.5 md:flex-row;

    & .text {
      @apply flex items-center gap-1.5 text-center font-bold;
    }

    & .timeLabel {
      @apply font-normal text-gray-500;
    }

    & .redLabel {
      @apply font-primary text-4xl font-bold text-red-base;
    }

    & .separator {
      @apply font-normal text-red-base;

      & + span {
        @apply font-normal;
      }
    }
  }

  &.small {
    @apply mb-1 gap-1 text-xs text-black;

    & .text {
      @apply text-black;

      &.red {
        @apply text-red-base;
      }
    }
  }
}
