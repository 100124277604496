.finance {
  @apply sm:text-center;

  p {
    @apply text-base;
  }

  .ctas {
    @apply sm:flex sm:justify-center sm:gap-2;

    .button {
      @apply mb-3 w-full self-start font-secondary tracking-normal sm:w-auto;
    }
  }
}
