.facetsContainer {
  @apply hidden;
  @apply lg:relative lg:z-0 lg:block lg:[height:unset] lg:[width:unset];

  &:global(.open) {
    @apply fixed left-0 top-0 z-[100] block h-full w-full overflow-auto bg-white pb-20;

    & .facets {
      @apply px-2 shadow-none;
    }
  }

  & .facets {
    @apply mb-8 mt-0 pb-8 lg:h-fit;
    @apply bg-white shadow-[0_2px_10px_rgba(0,0,0,0.14)];

    & .facetsHeader {
      @apply flex justify-between;

      & .clearCloseFilters {
        @apply flex items-center gap-8;

        & :global(.clear) {
          @apply lg:hidden;
        }

        & .mobileFacetsClose {
          @apply mr-3 lg:hidden;
        }
      }
    }

    & .refinementTitle {
      @apply mb-0 flex items-center justify-start p-4 uppercase;
    }

    & .count {
      @apply ml-auto inline-flex text-sm text-gray-500;
    }

    & :global(#auction-panel):has(.refinementList.noRefinement) {
      & .refinementList {
        @apply before:hidden;
      }
    }

    & .refinementList,
    & .radioRefinementList {
      @apply before:block before:px-4 before:pt-3 before:content-[attr(data-subheading)];
      @apply before:text-sm before:text-gray-500;

      &:not([data-subheading]) {
        @apply before:hidden;
      }

      &.noRefinement {
        @apply hidden;
      }

      & ul {
        @apply mb-0;
      }

      & form {
        @apply relative mx-4 my-2 flex;

        &.filled,
        &:focus-within {
          & label {
            @apply -translate-y-1.5 scale-95 text-xs;
          }
        }

        & label {
          @apply pointer-events-none absolute left-[2.1rem] top-2.5 text-sm text-gray-400;
          @apply origin-top-left transform transition-transform;
        }

        & button[type='submit'] {
          @apply pointer-events-none;

          & svg {
            @apply absolute left-3 top-3.5 scale-150;
          }
        }

        & button[type='reset'] {
          & svg {
            @apply absolute right-1.5 top-3.5;
          }
        }

        & input {
          @apply w-full pl-8 pt-4 text-sm;
          @apply border border-gray-200;
          @apply appearance-none focus:outline-none;

          &:focus {
            @apply border-red-base;
          }

          &::-webkit-search-cancel-button {
            @apply appearance-none;
          }
        }
      }

      & :global(.ais-RefinementList-noResults) {
        @apply px-4 text-base italic;
      }

      & .item {
        @apply border-b border-gray-200 lg:transition-colors lg:delay-75 lg:ease-in-out;
        @apply hover:bg-gray-100;

        & label {
          @apply flex px-4 py-3;

          & .checkbox,
          & .radio {
            @apply relative inline-flex appearance-none gap-2 p-0;
            @apply before:mt-0.5 before:h-3.5 before:w-3.5 before:shrink-0;
            @apply before:border before:border-black;
          }

          & .checkbox {
            @apply before:rounded-sm;
          }

          & .radio {
            @apply before:rounded-full;
          }
        }

        & label,
        & input {
          @apply cursor-pointer;
        }

        & input {
          @apply shrink-0;
        }
      }

      & .label {
        @apply inline-flex pl-2 text-sm text-gray-650;

        & .liveAuction {
          @apply inline-flex after:ml-2 after:h-[1.1rem] after:w-5 after:animate-pulse after:bg-live-icon after:bg-[size:100%];
        }

        & .mainAttraction {
          @apply inline-flex after:ml-2 after:h-[1.1rem] after:w-5 after:animate-pulse after:bg-star-pin-icon after:bg-[size:100%];
        }

        &:has(mark) {
          @apply inline;

          & * {
            @apply align-text-bottom;
          }

          & .liveAuction,
          & .mainAttraction {
            @apply after:ml-1;
          }
        }
      }

      & .selectedItem {
        @apply text-red-base;

        & .label {
          @apply font-bold text-red-base;
        }

        & label {
          & .checkbox,
          & .radio {
            @apply before:border-red-base;
            @apply after:absolute after:left-[3px] after:top-[5px] after:h-2 after:w-2;
            @apply after:bg-red-base;
          }

          & .radio {
            @apply after:rounded-full;
          }
        }
      }

      & .showMore {
        @apply mx-auto my-4 block px-4 py-2;
        @apply bg-red-base text-white;
        @apply hover:bg-red-hover;

        &:disabled {
          @apply hidden;
        }
      }
    }

    & .showResults {
      @apply fixed bottom-0 left-0 right-0 flex justify-center bg-white pb-2 lg:hidden;

      & :global(.button) {
        @apply rounded-none py-2 font-body text-xl font-normal normal-case tracking-normal;
      }
    }
  }

  & .ctaContainer {
    @apply px-2 text-center;

    & :global(.button) {
      @apply py-2 text-base lg:text-lg;
    }

    & .link {
      @apply mt-2 block text-sm text-gray-600 underline;
    }
  }
}
